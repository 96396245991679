import axios from "axios";
import { getTokenfromLocalStorage } from "../../../../../utils/utilz";

const donerInstance = axios.create({
  baseURL: `http://3.111.68.56:3000`,
});
const token = getTokenfromLocalStorage();
export const getdonerInstanceListEffect = (payload:any) => {

  return donerInstance.request({
    url: `/doner/list?pagination_required=${payload.pagination_required}&pageNo=${payload.pageNo}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const adddonerInstanceListEffect = (formData:any) => {
  return donerInstance.request({
    url: `/doner/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  
};
export const deleteDonersEffect = (payload:any) => {
  return donerInstance.request({
    url: `/doner/delete/${payload?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
}
export const updatedonerInstanceListEffect = (formData:any) => {
  return donerInstance.request({
    url: `/doner/update`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  
};
export const uploadCsvEffect = (formData:any) => {
  return donerInstance.request({
    url: `/doner/bulkInsertDoner`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
  
};