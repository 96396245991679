import axios from "axios";
const authInstance = axios.create({
  baseURL: `http://3.111.68.56:3000`,
});
const userLoginEffect = (formData:any) => {
  const LoginData = authInstance.request({
    url: "/auth/login",                          
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return LoginData;
};
const RegisterEffect = (formData:any) => {
  const SigninData = authInstance.request({
    url: "/auth/register",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    }

  });
  

  return SigninData;
};
const otpEffect = (formData:any) => {
  const otpData = authInstance.request({
    url: "/auth/otpMail",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    }

  });
  

  return otpData;
};
const otpVerifyEffect = (formData:any) => {
  const otpData = authInstance.request({
    url: "/auth/otpVerification",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    }
  });
  

  return otpData;
};
const forgetPasswordEffect = (formData:any) => {
  const otpData = authInstance.request({
    url: "/auth/forgetPassword",
    method: "POST",
    data: formData,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    }
  });
  

  return otpData;
};

export { userLoginEffect, RegisterEffect ,otpEffect,otpVerifyEffect,forgetPasswordEffect};
        