import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useLocation } from 'react-router-dom';
import { SwipeableDrawer } from '@mui/material';
import { findIndex } from 'lodash';

import LeftMenuList from './components/LeftMenuList';
import AppFooter from './components/AppFooter';
import { APP_ROUTES } from '../common/constants/Routes';
import TopHeader from './components/TopHeader';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: any) => prop !== 'open',
})<AppBarProps>(({ theme, open }: any) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppLayout = () => {
  const theme = useTheme();
  const appBarRef = React.useRef<HTMLDivElement>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [contentHeight, setContentHeight] = React.useState(0);
  const [notificationDrawer, setNotificationDrawer] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (appBarRef.current) {
      setContentHeight(appBarRef.current.offsetHeight + 20);
    }
  }, [appBarRef, location?.pathname]);

  return (
    <Box className='app-layout-container'>
      <CssBaseline />
      <AppBar
        ref={appBarRef}
        position='fixed'
        sx={{ backgroundColor: theme.palette.common.white }}
        elevation={0}
      >
        <Toolbar className='p-0  tool-bar-container'>
          {findIndex(
            [
              APP_ROUTES.DASHBOARD,
              APP_ROUTES.VOLUNTEER,
              APP_ROUTES.DONER,
              APP_ROUTES.VOLUNTEERFORM,
              APP_ROUTES.ROLE,
              APP_ROUTES.ROLEFORM,
              APP_ROUTES.ROLEEDIT,
              APP_ROUTES.VOLUNTEEREDIT,
              APP_ROUTES.DONATE_ADD,
              APP_ROUTES.DONATE_EDIT,
              APP_ROUTES.DONATION_LIST,
              APP_ROUTES.PROFILE,
              APP_ROUTES.TEAM_MEMBER,
              APP_ROUTES.TEAM_MEMBER_FORM,

              APP_ROUTES.REPORT,
              APP_ROUTES.PAYMENTREMINDER,
              APP_ROUTES.CHANGE_PASSWORD,
              APP_ROUTES.DONATION_PRINT
             

            ],
            (val: any) =>
              val === (location.pathname === '' ? '/' : location.pathname)
          ) !== -1 ? (
            <TopHeader
              setDrawerOpen={setDrawerOpen}
              contentHeight={contentHeight}
              setNotificationDrawer={setNotificationDrawer}
            />
          ) : (
            ''
            // <BreadCrumps />
          )}
        </Toolbar>
      </AppBar>

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          paddingTop: `${contentHeight}px !important`,
        }}
        className='px-30 py-0'
      >
        <Outlet />
      </Box>
      {findIndex(
        [APP_ROUTES.DASHBOARD],
        (val: any) =>
          val === (location.pathname === '' ? '/' : location.pathname)
      ) !== -1 ? (
        <AppFooter />
      ) : (
        ''
      )}

      <SwipeableDrawer
        // className='notification-drawer-container'
        anchor='left'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <LeftMenuList setOpen={setDrawerOpen} />
      </SwipeableDrawer>
      {/* <SwipeableDrawer
        className='notification-drawer-container'
        anchor='right'
        open={notificationDrawer}
        onClose={() => setNotificationDrawer(false)}
        onOpen={() => setNotificationDrawer(true)}
      ></SwipeableDrawer> */}
    </Box>
  );
};

export default AppLayout;
