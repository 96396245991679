import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";


import rootSaga from "../saga";
import createRootReducer from "../auth/reducer/index";
import { thunk } from 'redux-thunk';

const createBrowserHistory = require("history").createBrowserHistory;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares  = [thunk, sagaMiddleware, routeMiddleware] as any;

export default function configureStore(preloadedState:any) {
  const store = createStore(
    createRootReducer(history), 
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), 
        ...middlewares 
      )
    )
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
