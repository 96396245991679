import axios from "axios";
import { getTokenfromLocalStorage } from "../../../../../utils/utilz";

const donerInstance = axios.create({
  baseURL: `
  http://3.111.68.56:3000`,
});
const token = getTokenfromLocalStorage();
export const getdonationInstanceListEffect = (payload:any) => {
  return donerInstance.request({
    url: `/donation/list?pagination_required=${payload.pagination_required}&pageNo=${payload.pageNo}&search_query=${payload.search_query}&from_date=${payload.from_date}&to_date=${payload.to_date}&donateType=${payload.donateType}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateDonationInstanceListEffect = (formData:any) => {

  return donerInstance.request({
    url: `/donation/update`,
    method: "PUT",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const addDonationInstanceListEffect = (formData:any) => {

  return donerInstance.request({
    url: `/donation/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getFamilyInstanceListEffect = (payload:any) => {

  return donerInstance.request({
    url: `/familyMember/list?doner_id=${payload}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getDonationInstanceEffect = (payload:any) => {
 
    return donerInstance.request({
      url: `/donation/get?donation_id=${payload}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };
  export const DonationByDateInstanceEffect = (payload:any) => {

    return donerInstance.request({
      url: `/donation/listByDate?donation_date=${payload.donation_date}&pagination_required=true&pageNo=${payload.pageNo}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };
  export const todayDonationInstanceEffect = (payload:any) => {
 
    return donerInstance.request({
      url: `/donation/listByCurrentDate`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };
  export const donationReportInstanceEffect = (payload:any) => {

    return donerInstance.request({
      url: `donation/getDonationsWithSum?pagination_required=true&pageNo=${payload.pageNo}&search_query=${payload.search_query}&from_date=${payload.from_date}&to_date=${payload.to_date}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };
  export const getRecentInstanceListEffect = (payload:any) => {

    return donerInstance.request({
      url: `/donation/recentDonations?pagination_required=true&pageNo=${payload.pageNo}&search_query=${payload.search_query}&from_date=${payload.from_date}&to_date=${payload.to_date}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };
  export const getCvsInstanceListEffect = (payload:any) => {
    return donerInstance.request({
      url: `/donation/list?pagination_required=false&pageNo=${payload.pageNo}&search_query=${payload.search_query}&from_date=${payload.from_date}&to_date=${payload.to_date}&donateType=${payload.donateType}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };