import axios from "axios";
import { getTokenfromLocalStorage } from "../../../../../utils/utilz";

const rolesInstance = axios.create({
  baseURL: `http://3.111.68.56:3000`,
});
const token = getTokenfromLocalStorage();

export const addRolesInstanceListEffect = (formData:any) => {
  return rolesInstance.request({
    url: `/roles/add`,
    method: "POST",
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  
};
export const getRolesInstanceListEffect = (formData:any) => {
  return rolesInstance.request({
    url: `/roles/list`,
    method: "GET",
   
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const deleteRolesInstanceListEffect = (formData:any) => {
  return rolesInstance.request({
    url: `/roles/delete/${formData.id}`,
    method: "DELETE",
   
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateRolesInstanceListEffect = (formData:any) => {
  
  return rolesInstance.request({
    url: `/roles/update/${formData[0].id}`,
    method: "PUT",
   data:formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
