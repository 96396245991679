import {
GET_DONATION_LIST_IN_PROGRESS,
GET_DONATION_LIST_SUCCESS,
GET_DONATION_LIST_ERROR,
UPDATE_DONATION_IN_PROGRESS,
UPDATE_DONATION_IN_SUCCESS,
UPDATE_DONATION_IN_ERROR,
ADD_DONATION_IN_PROGRESS,
ADD_DONATION_IN_SUCCESS,
ADD_DONATION_IN_ERROR,
GET_FAMILY_IN_PROGRESS,
GET_FAMILY_IN_SUCCESS,
GET_FAMILY_IN_ERROR,
GET_DONATION_IN_PROGRESS,
GET_DONATION_IN_SUCCESS,
GET_DONATION_IN_ERROR,
DONATION_BY_DATE_IN_PROGRESS,
DONATION_BY_DATE_IN_SUCCESS,
DONATION_BY_DATE_IN_ERROR,
TODAY_DONATION_IN_PROGRESS,
TODAY_DONATION_IN_SUCCESS,
TODAY_DONATION_IN_ERROR,
DONATION_REPORT_IN_PROGRESS,
DONATION_REPORT_IN_SUCCESS,
DONATION_REPORT_IN_ERROR,
GET_RECENT_DONATIONS_IN_PROGRESS,
GET_RECENT_DONATIONS_IN_SUCCESS,
GET_RECENT_DONATIONS_IN_ERROR,
GET_CSV_IN_PROGRESS,
GET_CSV_IN_SUCCESS,
GET_CSV_IN_ERROR
 } from "../../../constants/ActionType";
      
      const defaultStatus = {
        progressing: false,
        success: false,
        error: false,
        data: [],
      };
      const initialState = {
        donationList: { ...defaultStatus },
        updateDonation: { ...defaultStatus },
        addDonation: { ...defaultStatus },
        familyList: { ...defaultStatus },
        getDonation:{...defaultStatus},
        getDonationByDate:{...defaultStatus},
        getTodayDonation:{...defaultStatus},
        getDonationReport:{...defaultStatus},
        getRecentDonation:{...defaultStatus},
        getCsvReport:{...defaultStatus}
      };
      const donationReducer = (state = initialState, action:any) => {
        const { type, payload } = action;
        switch (type) {
          //DonationList
          case GET_DONATION_LIST_IN_PROGRESS:
            return {
              ...state,
              donationList: { ...defaultStatus, ...payload, progressing: true },
            };
          case GET_DONATION_LIST_SUCCESS:
            return {
              ...state,
              donationList: { ...defaultStatus, ...payload, success: true },
            };
          case GET_DONATION_LIST_ERROR:
            return {
              ...state,
              donationList: {
                ...defaultStatus,
                ...payload,
                error: true,
              },
            };
        //updateDonation
         case  UPDATE_DONATION_IN_PROGRESS:
      return {
        ...state,
        updateDonation: { ...defaultStatus, ...payload, progressing: true },
      };
    case UPDATE_DONATION_IN_SUCCESS:
      return {
        ...state,
        updateDonation: { ...defaultStatus, ...payload, success: true },
      };
    case UPDATE_DONATION_IN_ERROR:
      return {
        ...state,
        updateDonation: { ...defaultStatus, ...payload, error: true },
      };
       //addDonation
       case  ADD_DONATION_IN_PROGRESS:
        return {
          ...state,
          addDonation: { ...defaultStatus, ...payload, progressing: true },
        };
      case ADD_DONATION_IN_SUCCESS:
        return {
          ...state,
          addDonation: { ...defaultStatus, ...payload, success: true },
        };
      case ADD_DONATION_IN_ERROR:
        return {
          ...state,
          addDonation: { ...defaultStatus, ...payload, error: true },
        };
          //getFamilyList
       case  GET_FAMILY_IN_PROGRESS:
        return {
          ...state,
          familyList: { ...defaultStatus, ...payload, progressing: true },
        };
      case GET_FAMILY_IN_SUCCESS:
        return {
          ...state,
          familyList: { ...defaultStatus, ...payload, success: true },
        };
      case GET_FAMILY_IN_ERROR:
        return {
          ...state,
          familyList: { ...defaultStatus, ...payload, error: true },
        };
            //getDonation
       case  GET_DONATION_IN_PROGRESS:
        return {
          ...state,
          getDonation: { ...defaultStatus, ...payload, progressing: true },
        };
      case GET_DONATION_IN_SUCCESS:
        return {
          ...state,
          getDonation: { ...defaultStatus, ...payload, success: true },
        };
      case GET_DONATION_IN_ERROR:
        return {
          ...state,
          getDonation: { ...defaultStatus, ...payload, error: true },
        };
            //getDonationByDate
       case  DONATION_BY_DATE_IN_PROGRESS:
        return {
          ...state,
          getDonationByDate: { ...defaultStatus, ...payload, progressing: true },
        };
      case DONATION_BY_DATE_IN_SUCCESS:
        return {
          ...state,
          getDonationByDate: { ...defaultStatus, ...payload, success: true },
        };
      case DONATION_BY_DATE_IN_ERROR:
        return {
          ...state,
          getDonationByDate: { ...defaultStatus, ...payload, error: true },
        };
             //getTodayDonation
       case  TODAY_DONATION_IN_PROGRESS:
        return {
          ...state,
          getTodayDonation: { ...defaultStatus, ...payload, progressing: true },
        };
      case TODAY_DONATION_IN_SUCCESS:
        return {
          ...state,
          getTodayDonation: { ...defaultStatus, ...payload, success: true },
        };
      case TODAY_DONATION_IN_ERROR:
        return {
          ...state,
          getTodayDonation: { ...defaultStatus, ...payload, error: true },
        };
                    //getDonationReport
       case  DONATION_REPORT_IN_PROGRESS:
        return {
          ...state,
          getDonationReport: { ...defaultStatus, ...payload, progressing: true },
        };
      case DONATION_REPORT_IN_SUCCESS:
        return {
          ...state,
          getDonationReport: { ...defaultStatus, ...payload, success: true },
        };
      case DONATION_REPORT_IN_ERROR:
        return {
          ...state,
          getDonationReport: { ...defaultStatus, ...payload, error: true },
        };
                         //getDonationRecent
       case  GET_RECENT_DONATIONS_IN_PROGRESS:
        return {
          ...state,
          getRecentDonation: { ...defaultStatus, ...payload, progressing: true },
        };
      case GET_RECENT_DONATIONS_IN_SUCCESS:
        return {
          ...state,
          getRecentDonation: { ...defaultStatus, ...payload, success: true },
        };
      case GET_RECENT_DONATIONS_IN_ERROR:
        return {
          ...state,
          getRecentDonation: { ...defaultStatus, ...payload, error: true },
        };
                //getCsv
       case  GET_CSV_IN_PROGRESS:
        return {
          ...state,
          getCsvReport: { ...defaultStatus, ...payload, progressing: true },
        };
      case GET_CSV_IN_SUCCESS:
        return {
          ...state,
          getCsvReport: { ...defaultStatus, ...payload, success: true },
        };
      case GET_CSV_IN_ERROR:
        return {
          ...state,
          getCsvReport: { ...defaultStatus, ...payload, error: true },
        };
           default:
            return state;
        }
      };
      
      export default donationReducer;
      