import { call, put, takeLatest } from "@redux-saga/core/effects";

import { ADD_ROLES_IN_PROGRESS, DELETE_ROLESlIST_IN_PROGRESS, GET_ROLES_IN_PROGRESS, GET_ROLESlIST_IN_PROGRESS, UPDATE_ROLESlIST_IN_PROGRESS } from "../../constants/ActionType";
import { getErrorMessageFromAPI } from "../../../utils/utilz";
import { message } from "antd";
import { addRolesError, addRolesSuccess, deleteROLESError, deleteROLESSuccess, getRolesListInSuccess, updateRolesError, updateRolesSuccess } from "../roles/action/rolesAction";
import { addRolesInstanceListEffect, deleteRolesInstanceListEffect, getRolesInstanceListEffect, updateRolesInstanceListEffect } from "../roles/saga/effects/roleseffect";
import Message from "../../../components/Message/Message";


function* addRoles({ payload }:any) {
  try {

    let { data } = yield call(addRolesInstanceListEffect, payload.payload);

    yield put(
      addRolesSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSuccess
      ){
      payload.handleSuccess()
    }
  } catch (e:any) {
    yield put(
      addRolesError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* getRolesList({ payload }:any) {
  try {
    const { data } = yield call(getRolesInstanceListEffect, payload);

    yield put(
      getRolesListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    // yield put(
    //     getTeamMemberListInError({
    //     message: getErrorMessageFromAPI(e),
    //     error: true,
    //   })
    // );
    // message.error(getErrorMessageFromAPI(e));
  }
}
function* deleteRoles({ payload }:any) {
  try {
    
    let { data } = yield call(deleteRolesInstanceListEffect, payload);

    yield put(
      deleteROLESSuccess({
        data: data,
        success: true,
      })
    );
    if(payload.handleSuccess){
 
      payload.handleSuccess()
    }
    yield call(Message as any, { type: "success", content: data.message });
  } catch (e:any) {
    yield put(
      deleteROLESError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* updateRoles({ payload }:any) {
  try {

    let { data } = yield call(updateRolesInstanceListEffect, payload.payload);

    yield put(
      updateRolesSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSuccess
      ){
      payload.handleSuccess()
    }
  } catch (e:any) {
    yield put(
      updateRolesError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}

export default function* rolesSaga() {

    yield takeLatest(ADD_ROLES_IN_PROGRESS, addRoles);
    yield takeLatest(GET_ROLESlIST_IN_PROGRESS, getRolesList);
    yield takeLatest(DELETE_ROLESlIST_IN_PROGRESS, deleteRoles)
    yield takeLatest(UPDATE_ROLESlIST_IN_PROGRESS, updateRoles)
   
  }
