import { call, put, takeLatest } from "@redux-saga/core/effects";

import {
GET_DONER_LIST_IN_PROGRESS,
GET_DONER_LIST_SUCCESS,
GET_DONER_LIST_ERROR,
ADD_DONER_IN_PROGRESS,
DELETE_DONER_IN_PROGRESS,
UPDATE_DONER_IN_PROGRESS,
ADD_CSV_IN_PROGRESS
} from "../../constants/ActionType";
import { getErrorMessageFromAPI } from "../../../utils/utilz";
import { message } from "antd";
import {
getDonerListInProgress,
getDonerListInSuccess,
getDonerListInError,
addDonerListSuccess,
addDonerInError,
deleteDONERInProgress,
deleteDONERSuccess,
deleteDONERError,
updateDonerListSuccess,
updateDonerInError,
addCsvError,
addCsvSuccess
} from "../doner/action/donerAction";
import {
  getdonerInstanceListEffect,
  adddonerInstanceListEffect,
  deleteDonersEffect,
  updatedonerInstanceListEffect,
  uploadCsvEffect
} from "../doner/saga/effects/donerEffect";
import Message from "../../../components/Message/Message";

function* getDonerList({ payload }:any) {
  try {
    const { data } = yield call(getdonerInstanceListEffect, payload);

    yield put(
        getDonerListInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    yield put(
        getDonerListInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}
function* addDoner({ payload }:any) {
  try {
  
    let { data } = yield call(adddonerInstanceListEffect, payload.data);

    yield put(
      addDonerListSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSucces
      ){
      payload.handleSucces()
    }
  } catch (e:any) {
    yield put(
      addDonerInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* deleteDoner({ payload }:any) {
  try {
    
    let { data } = yield call(deleteDonersEffect, payload);

    yield put(
      deleteDONERSuccess({
        data: data,
        success: true,
      })
    );
    if(payload.handleSuccess){

      payload.handleSuccess()
    }
    yield call(Message as any, { type: "success", content: data.message });
  } catch (e:any) {
    yield put(
      deleteDONERError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* updateDoner({ payload }:any) {
  try {

    let { data } = yield call(updatedonerInstanceListEffect, payload.data);

    yield put(
      updateDonerListSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSucces
      ){
      payload.handleSucces()
    }
   
  } catch (e:any) {
    yield put(
      updateDonerInError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* addCsv({ payload }:any) {
  try {
    let { data } = yield call(uploadCsvEffect, payload.formData);
    yield put(
      addCsvSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSuccess
      ){
      payload.handleSuccess()
    }
  } catch (e:any) {
    yield put(
      addCsvError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
export default function* donerSaga() {
    yield takeLatest(GET_DONER_LIST_IN_PROGRESS, getDonerList);
    yield takeLatest(ADD_DONER_IN_PROGRESS, addDoner);
    yield takeLatest(DELETE_DONER_IN_PROGRESS, deleteDoner);
    yield takeLatest(UPDATE_DONER_IN_PROGRESS, updateDoner);
    yield takeLatest(ADD_CSV_IN_PROGRESS, addCsv);
  }
