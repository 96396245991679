import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { APP_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
// import PageNotFound from '../common/ui/PageNotFound';

import AppLayout from './AppLayout';
import DashboardScreen from '../features/App/DashboardScreen/DashboardScreen';
import Volunteer from '../features/App/Volunteer/Volunteer';
import Doners from '../features/App/Doners/Doners';
import DonerForm from '../features/App/Doners/DonerForm';
import Role from '../features/App/Role/Role';
import RoleFrom from '../features/App/Role/RoleFrom';
import RoleEdit from '../features/App/Role/RoleEdit';
import AddVolunteer from '../features/App/Volunteer/AddVolunteer';
import EditVolunteer from '../features/App/Volunteer/EditVolunteer';
import AddDonate from '../features/App/Donate/AddDonate';
import DonationList from '../features/App/Donate/DonationList';
import ProfileScreen from '../features/App/ProfileScreen/ProfileScreen';
import TeamMember from '../features/App/TeamMember/TeamMember';
import TeamMemberForm from '../features/App/TeamMember/TeamMemberForm';
import Reports from '../features/App/Report/Reports';
import PaymentReminder from '../features/App/PaymenReminder/PaymentReminder';
import ChangePassword from '../features/App/changePassword/ChangePassword';
import DonationPrint from '../common/DonationPrint';

const AppRoutes = ({ isLogged }: any) => {
  const location = useLocation();

  return (
    <Routes>
      {isLogged === true ? (
        <Route path='/' element={<AppLayout />}>
          <Route path={APP_ROUTES.DASHBOARD} element={<DashboardScreen />} />
          <Route path={APP_ROUTES.VOLUNTEER} element={<Volunteer />} />
          <Route path={APP_ROUTES.DONER} element={<Doners />} />
          <Route path={APP_ROUTES.VOLUNTEERFORM} element={<AddVolunteer />} />
          <Route path={APP_ROUTES.VOLUNTEEREDIT} element={<EditVolunteer />} />
          <Route path={APP_ROUTES.DONER_ADD} element={<DonerForm />} />
          <Route path={APP_ROUTES.DONER_EDIT} element={<DonerForm />} />
          <Route path={APP_ROUTES.ROLE} element={<Role />} />
          <Route path={APP_ROUTES.ROLEFORM} element={<RoleEdit />} />
          <Route path={APP_ROUTES.ROLEEDIT} element={<RoleEdit />} />
          <Route path={APP_ROUTES.DONATE_ADD} element={<AddDonate />} />
          <Route path={APP_ROUTES.DONATE_EDIT} element={<AddDonate />} />
          <Route path={APP_ROUTES.DONATION_LIST} element={<DonationList />} />
          <Route path={APP_ROUTES.PROFILE} element={<ProfileScreen />} />
          <Route path={APP_ROUTES.TEAM_MEMBER} element={<TeamMember/>} />
          <Route path={APP_ROUTES.TEAM_MEMBER_FORM} element={<TeamMemberForm/>} />
          <Route path={APP_ROUTES.TEAM_MEMBER_EDIT} element={<TeamMemberForm/>} />
          <Route path={APP_ROUTES.REPORT} element={<Reports />} />
          <Route
            path={APP_ROUTES.PAYMENTREMINDER}
            element={<PaymentReminder />}
          />
           <Route
            path={APP_ROUTES.CHANGE_PASSWORD}
            element={<ChangePassword/>}
          />
            <Route
            path={APP_ROUTES.DONATION_PRINT}
            element={<DonationPrint/>}
          />
          {!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
            <Route path='*' element={<Navigate to={APP_ROUTES.DASHBOARD} />} />
          ) : (
            <></>
          )}
        </Route>
      ) : (
        <></>
      )}
    </Routes>
  );
};

export default AppRoutes;
