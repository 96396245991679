/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Grid,
  IconButton,
  List,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../Layout.scss';
import { Link, useNavigate } from 'react-router-dom';

// import { ReactComponent as CloseIcon } from '../../common/icons/close.svg';
import CloseIcon from '@mui/icons-material/Close';

import {
  APP_ROUTES,
  AUTH_ROUTES,
  PUBLIC_ROUTES,
} from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import cryptoEncryptionDecryption from '../../common/crypo';
import { UserLogoutInProgress } from '../../common/redux/auth/action/authAction';
import { useDispatch } from 'react-redux';
import { getProfileInProgress } from '../../common/redux/user/action/userAction';
import LanguageSelector from '../../features/Auth/Login/LanguageSelector';
import { useTranslation } from 'react-i18next';

const LeftMenuList = ({ setOpen }: { setOpen: Function }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const [showLanguageModal, setShowLanguageModal] = useState(false);

  const handleMenuItemClick = () => {
    setOpen(false);
  };
  const handleLogOff = () => {
    setOpen(false);
    localStorage.removeItem('user');
    navigate(APP_ROUTES.DASHBOARD);
    window.location.reload();
    dispatch(UserLogoutInProgress())
  };

  const changeLanguage = () => {
    setShowLanguageModal(true);
    setOpen(false);
  };
  const permissions= localStorage.getItem("permissions");
  const role_name= localStorage.getItem("role_name");
 const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
 const decrptedRole= cryptoEncryptionDecryption.Decrypt(role_name)

 const decryptedPermissions = JSON.parse(decrptedPermissions);
 
 const decryptedPermissions3 =JSON.parse(decryptedPermissions)
 const decryptedRoleName = JSON.parse(decrptedRole);

 
  useEffect(()=>{
    const paylod={
      page:1
    }
    dispatch(getProfileInProgress(paylod))
  },[])




  return (
    <div className='notification-container'>
      <Grid
        container
        justifyContent='space-between'
        className='notification-drawer-header'
        alignItems='center'
      >
        <Grid item mt={5}>
          <Grid item mt={5}>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Grid item>
              <List className='left-menu-container'>
                <Link to={APP_ROUTES.DASHBOARD} onClick={handleMenuItemClick}>
                  <ListItemText
                    className='menu-item-container mt-30'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        {t('DASHBOARD')}
                      </Typography>
                    }
                  />
                </Link>
                {/* <Link to={APP_ROUTES.VOLUNTEER} onClick={handleMenuItemClick}>
               {!decryptedPermissions?.volunteer.noaccess&&(<ListItemText
                    className='menu-item-container mt-30'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        Volunteer
                      </Typography>
                    }
                  />) }
                  
                </Link> */}
                {decryptedRoleName.toUpperCase()==="ADMIN"||"ADMINS"&&(
                <Link to={APP_ROUTES.ROLE} onClick={handleMenuItemClick}>
                 <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                       {t('Manage_Roles')}
                      </Typography>
                    }
                  />
                 
                </Link>)}
                
                <Link to={APP_ROUTES.TEAM_MEMBER} onClick={handleMenuItemClick}>
                {!decryptedPermissions3?.Team_Member?.noaccess&&( <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                      {t('Manage_Team_Members')}
                      </Typography>
                    }
                  />)}
                 
                </Link>
                <Link to={APP_ROUTES.DONER} onClick={handleMenuItemClick}>
          
                {!decryptedPermissions3?.Donor?.noaccess&&(
                <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        {t('Manage_Donors')}
                   
                      </Typography>
                    }
                  />)}
                  
                </Link>
                <Link
                  to={APP_ROUTES.DONATION_LIST}
                  onClick={handleMenuItemClick}
                >
                     {!decryptedPermissions3?.Donor?.noaccess&&( <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        {t('Manage_Donation')}
                      </Typography>
                    }
                  />)}
                 
                </Link>
                <Link
                  to={APP_ROUTES.PAYMENTREMINDER}
                  onClick={handleMenuItemClick}
                >

                  <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                         {t('Recent_donations')}
                      
                      </Typography>
                    }
                  />
                </Link>

                <Link to={APP_ROUTES.REPORT} onClick={handleMenuItemClick}>
                  <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        {t('Reports')}
                      </Typography>
                    }
                  />
                </Link>
                <Link to={APP_ROUTES.PROFILE} onClick={handleMenuItemClick}>
                  <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        {t('MY_PROFILE')}
                      </Typography>
                    }
                  />
                </Link>

                <Link to={APP_ROUTES.CHANGE_PASSWORD} onClick={handleMenuItemClick}>
                  <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        {t('CHANGE_PASSWORD')}
                      </Typography>
                    }
                  />
                </Link>
                <Link to={""} onClick={() => setShowLanguageModal(true)}>
                <ListItemText
													// className={`menu-item-container mt-30${
													// 	index > 0 ? ' mt-10' : ''
													// }`}
													primary={
                            
														<Typography
															color={theme.palette.primary.main}
															fontWeight={600}
															fontSize={18}
															
														>
															{t('SELECT_LANGUAGE')}
														</Typography>
													}
												/>
                        </Link>
                <Link to={'#'} onClick={handleLogOff}>
                  <ListItemText
                    className='menu-item-container'
                    primary={
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight={600}
                        fontSize={18}
                      >
                        {t('Log_Out')}
             
                      </Typography>
                    }
                  />
                </Link>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <LanguageSelector
				open={showLanguageModal}
				setOpen={setShowLanguageModal}
				contentType="DASHBOARD"
			/>
      </Grid>
    </div>
  );
};

export default LeftMenuList;
