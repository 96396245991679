import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import './generator.scss';
import reportWebVitals from './reportWebVitals';
import configureStore, { history } from './common/redux/store';
import App from './app/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// Provide initial state if any
const initialState = {}; // Replace this with your initial state if you have any

const store = configureStore(initialState);

root.render(
  <Provider store={store}>
    <Suspense>
      <App />
      </Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
