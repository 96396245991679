import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './dashboard.scss';
import person from '../../../common/icons/volunteer.png';
import donationImg from '../../../common/icons/donation (2).png';
import donors from '../../../common/icons/donors.png';
import teamMembers from '../../../common/icons/teamMember (2).png';
import coin from '../../../common/icons/Coin in Hand.png';
import volunteers from '../../../common/icons/Volunteering.png';
import donations from '../../../common/icons/Donation.png';
import users from '../../../common/icons/Users.png';
import theme from '../../../common/theme';
import Table from '../../../common/TableComponent';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getTeamMemberListInProgress } from '../../../common/redux/teamMembers/action/teamMemberAction';
import { getDonerListInProgress } from '../../../common/redux/doner/action/donerAction';
import { getDonationBydateInProgress, getDonationListInProgress, getTodayDonationInProgress } from '../../../common/redux/Donations/actions/donationAction';
import { DatePicker, Spin } from 'antd';
import TableComponent from '../../../common/TableComponent';
import cryptoEncryptionDecryption from '../../../common/crypo';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { convertDateFormat } from '../../../utils/utilz';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getProfileInProgress } from '../../../common/redux/user/action/userAction';

function DashboardScreen() {
  const [tableRow, setTableRow] = useState([]);
  const { t } = useTranslation();
  useEffect(()=>{
    const paylod ={
      pageNo:1,
      pagination_required:"true"
    }
dispatch(getTeamMemberListInProgress(paylod))
dispatch(getDonerListInProgress(paylod))
dispatch(getDonationListInProgress(paylod)) 
dispatch(getTodayDonationInProgress(paylod)) 


  },[])
  const dispatch=useDispatch()
  useEffect(()=>{
    const paylod={
      page:1
    }
    dispatch(getProfileInProgress(paylod))
  },[])
  const  {teamMember} = useSelector((state:any) => state);
  const teamMemberData = teamMember?.teamMemberList?.data?.totalRecords
  || 0;
  const  {doner} = useSelector((state:any) => state);
  const donorData = doner?.donerList?.data?.totalRecords || [];
  
  const  {donation} = useSelector((state:any) => state);
  const donationData = donation?.donationList?.data?.totalRecords || [];
  const donationDataByDate = donation?.getDonationByDate?.data?.data|| [];
  const totalRecords= donation?.getDonationByDate?.data?.totalRecords|| [];
  const TodayDonations=donation?.getTodayDonation
  ?.data?.totalRecords||0;

  const donorDatas = donation?.donationList?.data?.data || [];
  const totalRecords2= donation?.donationList?.data?.totalRecords|| [];
  const donationLoading = donation?.donationList?.progressing;
  const donationDataByDateLoading = donation?.getDonationByDate?.progressing;
  const teamMemberDataLoading = teamMember?.teamMemberList?.progressing;
  const TodayDonationLoading=donation?.getTodayDonation?.progressing;
  const donorDataLoading = doner?.donerList?.progressing;
  
 const currentPage  = donation?.donationList?.data?.currentPage || 0;
 const currentPage2  = donation?.getDonationByDate?.data?.currentPage || 0;


 const [date, setDate] = useState<moment.Moment | null | undefined>();

const handleChange=(date:any)=>{
  setDate( date?.format('YYYY-MM-DD'))
  const payload = {
    donation_date: date?.format('YYYY-MM-DD')?date?.format('YYYY-MM-DD'):"", // Format the date as 'YYYY-MM-DD'
    pageNo: 1
  };

  dispatch(getDonationBydateInProgress(payload));
}
useEffect(()=>{
  const payload = {
    donation_date:"", // Format the date as 'YYYY-MM-DD'
    pageNo: 1
  };
  dispatch(getDonationBydateInProgress(payload));
},[])
const handlePageChange = (page:any) => {
  const payload = {
    donation_date: date?date:"", 
    pageNo: page
  };
  dispatch(getDonationBydateInProgress(payload));
};
const handlePageChange2= (page:any) => {
  const payload = {
    donation_date: date, 
    pageNo: page,
   
      pagination_required:"true"
 
  };
  dispatch(getDonationListInProgress(payload));
};

  const columns = [
    {
      dataIndex: 'id',
      title: 'ID',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'DonateType',
      title: `${t('Donate_Type')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'DonatedBY',
      title: `${t('Donated_by')}`,
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
    },
    {
      dataIndex: 'DonationFor',
      title: `${t('Donated_For')}`,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },

    {
      dataIndex: 'PaymentDate',
      title: `${t('Donated_date')}`,
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];
  
  const rows = donationDataByDate?.map((donationItem: any) => ({
 
    id: donationItem?.donations_serial_no,
    recurringDonation:donationItem?.id,
    DonationFor: donationItem?.donatedFor,
    DonatedBY: donationItem?.Doner_name,
    PaymentDate: convertDateFormat(donationItem?.donatedDate),
    DonateType: donationItem?.donateType === '1' ? 'Cash' : 'Material', 
     
  }));
  const rows2= donorDatas?.map((donationItem: any) => ({
 


    id: donationItem?.donations_serial_no,
    recurringDonation:donationItem?.id,
    DonationFor: donationItem?.donatedFor,
    DonatedBY: donationItem?.Doner_name,
    PaymentDate: convertDateFormat(donationItem?.donatedDate),
    DonateType: donationItem?.donateType === '1' ? 'Cash' : 'Material', 
  
  
    
    
  }));
  const permissions= localStorage.getItem("permissions");
  const decrptedPermissions= cryptoEncryptionDecryption.Decrypt(permissions)
  const decryptedPermissions = JSON.parse(decrptedPermissions);
 
  const decryptedPermissions3 =JSON.parse(decryptedPermissions);

  const noAccess=decryptedPermissions3?.Donate?.noaccess

  return (
    <div>
   
      <Grid item xs={12} container display='flex' justifyContent='space-around' className='dashboard-grid'>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={2.5}
          xl={2}
          className='team-member-grid'
          px={2}
        >
          {teamMemberDataLoading ?<Grid>
          <Spin
               size="large"  /> </Grid>:
               <>
          <Grid mb={8} mt={8}>
            <img src={teamMembers} className='volunteer-image' />
          </Grid>
          <Grid>
            <Typography
              color={theme.palette.common.white}
              fontSize={40}
              fontWeight={600}
              textAlign={'center'}
            >
             {teamMemberData}
            </Typography>
            <Link to={APP_ROUTES.TEAM_MEMBER}>
            <Typography
              color={theme.palette.common.white}
              fontSize={18}
              fontWeight={600}
              ml={1}
            >
              {t('No_of_team_members')}
            </Typography>
            </Link>
          </Grid>
          </>
}
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={2.5}
          xl={2}
          className='Volunteers-grid'
        >
          {TodayDonationLoading
          ?<Grid>
          <Spin
               size="large"  /> </Grid>:
               <>
          <Grid mb={8} mt={8}>
            {' '}
            <img src={person} className='volunteer-image' />
          </Grid>
          <Grid>
            <Typography
              color={theme.palette.common.white}
              fontSize={40}
              fontWeight={600}
              textAlign={'center'}
            >
            {TodayDonations}
            </Typography>
            <Link to={APP_ROUTES.DONATION_LIST}>
            <Typography
              color={theme.palette.common.white}
              fontSize={18}
              fontWeight={600}
            >
            
             {t('Today_Donations')}
            </Typography></Link>
          </Grid>
          </>
          }
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={2.5}
          xl={2}
          className='Doners-grid'
        >
          {donorDataLoading?<Grid>
          <Spin
               size="large"  /> </Grid>:
               <>
          <Grid mb={8} mt={8}>
            <img src={donors} className='volunteer-image' />
          </Grid>
          <Grid>
            {' '}
            <Typography
              color={theme.palette.common.white}
              fontSize={40}
              fontWeight={600}
              textAlign={'center'}
            >
              {donorData}
            </Typography>
            <Link to={APP_ROUTES.DONER}>
            <Typography
              color={theme.palette.common.white}
              fontSize={16}
              fontWeight={600}
              
            >

              {t('No_of_Donors')}
            </Typography></Link>
          </Grid>
       </> }
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          lg={2.5}
          xl={2}
          className='Donations-grid'
        >
           {donationLoading?<Grid >
    <Spin
         size="large"  /> </Grid>: 
         <>
          <Grid mb={8} mt={8}>
            <img src={donationImg} className='volunteer-image' />
          </Grid>
          <Grid>
            <Typography
              color={theme.palette.common.white}
              fontSize={40}
              fontWeight={600}
              textAlign={'center'}
            >
              {donationData}
            </Typography>
            <Link to={APP_ROUTES.DONATION_LIST}>
            <Typography
              color={theme.palette.common.white}
              fontSize={18}
              fontWeight={600}
            >
              
              {t('No_of_Donations')}
            </Typography></Link>
          </Grid>
          </>}
        </Grid>
      
      </Grid>
      <Grid
        item
        xs={11}
        container
        display='flex'
        justifyContent='space-between'
        className='data-grid'
      >
          {!noAccess&&(
          <>
          {decryptedPermissions3.Donate.view&&(<>   <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5.5} className='tables-grid' mb={3}>
          <Grid  display={"flex"} justifyContent={"space-around"} alignItems={"center"}>
            <Grid>
           
          <Typography
            textAlign={'center'}
            fontSize={25}
            color={theme.palette.common.black}
            fontWeight={600}
            mt={1}
            mb={1}
          >
             {t('Upcoming_Donations')}
          </Typography>
          </Grid>
          <Grid  >
          <DatePicker onChange={handleChange}/>
          </Grid>
          </Grid>
          { donationDataByDateLoading
          ?<Grid className='main-spinner'>
          <Spin
               size="large"  /> </Grid>:
               <TableComponent
               columns={columns}
               dataSource={rows}
               pagination={{
               
                 total: totalRecords,
                 pageSize: 10, 
               current: currentPage2, //
                 onChange: handlePageChange
               }}
               type ="dashboard"
          
       
             
               // onChange={onChange}
               // ref={tableRef}
             /> }
         
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5.5} className='tables-grid' mb={3}>
          <Typography
            textAlign={'center'}
            fontSize={25}
            color={theme.palette.common.black}
            fontWeight={600}
            mt={1}
            mb={1}
          >
           {t('Recent_donations')}
          </Typography>
        {donationLoading?<Grid className='main-spinner'>
    <Spin
         size="large"  /> </Grid>: 
          <TableComponent
        columns={columns}
        dataSource={rows2}
        pagination={{
        
          total: totalRecords2,
          pageSize: 10, 
          current: currentPage, //
          onChange: handlePageChange2
        }}
   
        type ="dashboard"
      
        // onChange={onChange}
        // ref={tableRef}
      />}
        </Grid></>)}
       </> )}
       
      </Grid>
    </div>
  );
}

export default DashboardScreen;
