import { call, put, takeLatest } from "@redux-saga/core/effects";

import {  GET_PROFILE_IN_PROGRESS, UPDATE_PASSWORD_IN_PROGRESS, UPDATE_ROLESlIST_IN_PROGRESS, UPDATE_USER_IN_PROGRESS } from "../../constants/ActionType";
import { getErrorMessageFromAPI } from "../../../utils/utilz";
import { message } from "antd";
import { getProfileInError, getProfileInSuccess, updatePasswordError, updatePasswordSuccess, updateUserError, updateUserSuccess } from "../user/action/userAction";
import { getProfileInstanceEffect, updatePasswordInstanceEffect, updateUserInstanceListEffect } from "../user/saga/userEffects/userEffects";
import Message from "../../../components/Message/Message";



function* updateUser({ payload }:any) {
  try {

    let { data } = yield call(updateUserInstanceListEffect, payload.data);

    yield put(
      updateUserSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSuccess
      ){
      payload.handleSuccess()
    }
  } catch (e:any) {
    yield put(
      updateUserError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* updatePassword({ payload }:any) {
  try {

    let { data } = yield call(updatePasswordInstanceEffect, payload.data );

    yield put(
      updatePasswordSuccess({
        data: data,
        success: true,
      })
    );
    yield call(Message as any, { type: "success", content: data.message });
    if(payload.handleSuccess
      ){
      payload.handleSuccess()
    }
  } catch (e:any) {
    yield put(
      updatePasswordError({
        error: true,
        message: e["message"],
      })
    );
    yield call(Message as any, {
      type: "error",
      content: getErrorMessageFromAPI(e),
    });
  }
}
function* getProfile({ payload }:any) {
  try {
    const { data } = yield call(getProfileInstanceEffect, payload);

    yield put(
        getProfileInSuccess({
        data: data,
        message: data["message"],
        authSuccess: true,
      })
    );
  } catch (e:any) {
    yield put(
        getProfileInError({
        message: getErrorMessageFromAPI(e),
        error: true,
      })
    );
    message.error(getErrorMessageFromAPI(e));
  }
}

export default function* userSaga() {

   
    yield takeLatest(UPDATE_USER_IN_PROGRESS, updateUser)
    yield takeLatest(UPDATE_PASSWORD_IN_PROGRESS, updatePassword)
    yield takeLatest(GET_PROFILE_IN_PROGRESS, getProfile)
   
  }
